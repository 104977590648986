import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Call Recording: Disabled play button when the file is no longer available.`}</li>
      <li parentName="ul">{`SMARKIO: Added possibility to Google Analytics 4 dimensions.`}</li>
      <li parentName="ul">{`Aggregation Panels: Added the functionality to pick options from fields on Panel Rules.`}</li>
      <li parentName="ul">{`Bug Fix: By adding channels as rules and using these rules as filters on metrics, the correct data is returned.`}</li>
      <li parentName="ul">{`Bug fix: Visit values are consistent between V3 and V4 Aggregation Panels.`}</li>
      <li parentName="ul">{`Bug fix: Data showing up on Call Center Activity Graphics on Call App is correct again. `}</li>
      <li parentName="ul">{`Visitor: Validate if there are contents to show (variants from A/B Test) for Visitor.`}</li>
      <li parentName="ul">{`Bug fix: Fixed screen with a problem with restyling.`}</li>
      <li parentName="ul">{`Bug fix: fixed bug on Distributions - Load Balance settings.`}</li>
      <li parentName="ul">{`Bug fix: fixed bug on Timetable settings.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      